module.exports = {
    besidesBeta: ["eng", "tam"],
    active: "active",
    inActive: "inActive",
    deactivate: "deactivate",
    deactivated: "deactivated",
    success: "success",
    notExist: "notExist",
    inValid: "invalid",
    failure: "failure",
    alreadyExist: "alreadyExist",
    notGenerated: "notGenerated",
    joined: "joined",
    invited: "invited",
    pending: "pending",
    invalidSessionReq: "Invalid Session Request",
    invalidToken: "inValidToken",
    emailAlreadyExist: "emailAlreadyExist",
    mappingExist: "mappingExist",
    updated: "updated",
    invalidFormat: "invalidFormat",
    completed: "completed",
    approved: "approved",
    rejected: "rejected",
    invalidOtp: "invalidOtp",
    deletedAccount: "Your Account is Deleted,Please contact to Admin !",
    inActiveAccount: "inActiveAccount",
    invalidAccount: "invalidAccount",
    passwordMismatch: "passwordMismatch",
    invalidPassword: "invalidPassword",
    authText: "Teqbahn@321#",
    // For Chat:
    channelType1: "channel",
    channelType2: "direct",
    channelType3: "feed",
    channelType4: "meetings",
    channelType5: "task",
    channelType6: "educationTask",
    messageTypeMeeting: "meeting",
    messageTypeMeetingNotification: "meetingNotification",
    messageTypeChat: "chat",
    botRequest: "botRequest",
    botResponse: "botResponse",
    messageTypeSharedChat: "sharedchat",
    messageTypeCall: "call",
    orgTypeCompany: "company",
    orgTypeEducation: "education",
    orgTypeCommunityService: "communityService",
    orgTypeHealthcareService: "HealthcareService",
    orgTypeCustomerSupport: "customerSupport",
    orgTypeMultiplayerGaming: "multiplayerGaming",
    roleTypeAdmin: "Admin",
    roleTypeMember: "member",
    roleTypeMemberAdmin: "memberAdmin",
    roleTypeStudent: "student",
    roleTypeTeacher: "teacher",
    roleTypeMigrant: "migrant",
    roleTypeSurvivor: "survivor",
    roleTypeCommunityMember: "communityMember",
    roleTypeCbo: "cbo",
    roleTypeCustomerSupportMember: "customerSupportMember",
    roleTypeCustomerSupportMemberAdmin: "customerSupportMemberAdmin",
    roleTypeLeader: "leader",
    roleTypePlayer: "player",
    roleTypeProvider: "provider",
    vsCode: "vsCode",
    idKey: "idKey",
    add: "add",
    update: "update",
    delete: "delete",
    invalidRequest: "Invalid Request",
    limitInfiniteData: 25,
    //new
    reopened: "reopened",
    deleted: "deleted",
    accept: "accept",
    reject: "reject",
    assign: "assign",
    remove: "remove",
    //user online status
    status_available: "available", //online
    status_onCall: "onCall",
    status_notOnline: "notOnline",
    status_steppedAway: "steppedAway",
    systemLevelRoleAdmin: "admin",
    systemLevelRoleMember: "member",
    //inbox message status
    inbox_msg_status_unread: "unread",
    inbox_msg_status_read: "read",
    email: "email",
    mobileNumber: "mobileNumber",
    unVerified: "unVerified",
    workspaceTypeOrg: "organization",
    workspaceTypeGroup: "group",
    workspaceTypeCommunity: "community",
    workspaceStaus_verified: "verified",
    workspaceStaus_needDocument: "needDocuments",
    workspaceStaus_reject: "reject",
    status_notAvailable: "notAvailable",
    surveyText: "survey",
    searchChannelFeedback: "globalFeedback",
    searchGlobalSystemFeedback: "globalSystemFeedback",
    businessDesign: "business",
    technologyDesign: "technology",
    botResponseDefault: "Please wait while we are processing your request",
    defaultDocType: "teqdoc",
    type_user: "user",
    type_admin: "admin",
    messageTypeHealthcareBotRequest: "HealthcareBotRequest",
    messageTypeCSEventRequest: "CSEventRequest",
    messageTypeCSEventResponse: "CSEventResponse",
    messageTypeCSEventCaseStatusResponse: "CSEventCaseStatusResponse",
    messageTypeOpenMrsRequest: "OpenMrsRequest",
    messageTypeOpenMrsResponse: "OpenMrsResponse",
    messageTypeCSEventAutomaticResponse: "CSEventAutomaticResponse",
    msgExpired: "msgExpired",
    roleTypeLNSC: "lnsc",
    roleTypeCommunityServiceAdmin: "communityServiceAdmin",
    roleTypeHealthServiceAdmin: "healthServiceAdmin",
    roleTypeDoctor: "doctor",
    roleTypeNurse: "nurse",
    messageTypeCSEventLnscResponse: "CSEventLnscResponse",
    filterUserType: "user",
    filterAllUserType: "all",
    yes: "yes",
    no: "no",
    cancelled: "cancelled"
};