import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from "react-router-dom";
import {  toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { getDateWithFormat, getGrpcClient, getGrpcPollingClient, getUuidv4, checkActiveAdmin, checkMobileView } from "../../config/Common";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import ShareModal from "../../components/ShareModal";
import Loader from "../../components/Loader";
import MyConstant from "../../config/MyConstant";
import PaginationDatatable from "../../components/PaginationDatatable";
import Modal from "../../components/Modal";
import ViewedByModal from "../../components/ViewedByModal";
import CopyUrlButton from "../../components/CopyUrlButton";
import GlobalDomainCons from "../../config/GlobalDomainCons";
import GlobalEventCons from '../../config/GlobalEventCons';
import GlobalMsgCons from "../../config/GlobalMsgCons";



let toggleSpellCheck = true;
const AUTOBULLET_TRIGGER_CHARACTER = "1.";
const AUTOBULLET_VALID_CONTAINERS = [
    "HTMLTableCellElement",
    "HTMLParagraphElement",
    "HTMLDivElement",
    "HTMLElement",
];

function DocsWrite(props) {
    let { fromGuest } = props
    const auth = useSelector(state => fromGuest ? state.guestAuthReducer : state.authReducer);
    let { docsId, docsOrgId } = useParams()
    let [validate, setValidate] = useState("loading")
    let [editorState, setEditorState] = useState("")
    let [filename, setFilename] = useState("")
    let [tempFilename, setTempFilename] = useState("")
    let [editingUserId, setEditingUserId] = useState("")
    let [typingUser, setTypingUser] = useState("")
    let [versionModal, setVersionModal] = useState(false)
    let [versionContentLoading, setVersionContentLoading] = useState(false)
    let [versionContent, setVersionContent] = useState("")
    let [docsRefresh, setDocsRefresh] = useState(true)
    let [docsCreatedOrgId, setDocsCreatedOrgId] = useState("")
    const [parentOrgInfo, setParentOrgInfo] = useState({});
    let [restoreButtonLoading, setRestoreButtonLoading] = useState({});
    let [ownerId, setOwnerId] = useState("");
    let [lastModifiedAt, setLastModifiedAt] = useState("");
    let [sharedUserList, setSharedUserList] = useState({})
    let [docsEditRequestUserId, setDocsEditRequestUserId] = useState("")
    let [docsEditRequestModal, setDocsEditRequestModal] = useState(false)
    let [docsReplyUserId, setDocsReplyUserId] = useState("")
    let [docsReplyMsg, setDocsReplyMsg] = useState("")
    let [docsNotifiShow, setDocsNotifiShow] = useState(false)
    let [requestAccessShow, setRequestAccessShow] = useState(false)
    let [requestAccessData, setRequestAccessData] = useState({})




    const editorRef = useRef(null);

    if (docsId === undefined) {
        if (props.pinTabInfo.id) {
            docsId = props.pinTabInfo.id
        } else {
            docsId = props.id
        }
    }

    useEffect(() => {
        let activeCompanyId = ""
        if (docsOrgId) {
            activeCompanyId = docsOrgId
        } else if (props.orgId) {
            activeCompanyId = props.orgId
        } else {
            activeCompanyId = auth.activeCompany;
        }
        setDocsCreatedOrgId(activeCompanyId)

        let spellCheck = localStorage.getItem("spellCheck")
        toggleSpellCheck = true;
        if (spellCheck === "off") {
            toggleSpellCheck = false
        }
        ReactTooltip.rebuild();
        return () => {
            ReactTooltip.hide()
        };

    }, [])

    useEffect(() => {
        if (docsEditRequestUserId) {
            if (sharedUserList[docsEditRequestUserId] && editingUserId !== auth.loginId) {
                let { emailId } = sharedUserList[docsEditRequestUserId]
                emailId = capitalizeFirstLetter(emailId)
                toast.info(`${emailId} has sent a request to edit the document`, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }, [docsNotifiShow])

    useEffect(() => {
        if (docsCreatedOrgId) {
            if (auth.parentOrgInfo && Object.keys(auth.parentOrgInfo).length > 0) {
                let dataObj = { ...auth.parentOrgInfo, ...{ docsOrgId: docsCreatedOrgId } }
                setParentOrgInfo(dataObj)
            }
            validateAccess()
        }
    }, [docsCreatedOrgId])




    useEffect(() => {
        if (filename) {
            localStorage.setItem("file_" + docsId, filename)
        }
    }, [filename])

    useEffect(() => {
        if (validate === "verified") {
            if (props.setActiveDocsId) {
                props.setActiveDocsId(docsId);
            }
            if (props.setActiveOrgId) {
                props.setActiveOrgId(docsOrgId);
            }
            if (props.history.location.state) {
                let { content } = props.history.location.state;
                if (content) {
                    onChange(content)
                }
            }
            userSesssion("add");
            getSharedUsersDetails()

            const interval = setInterval(() => {
                getDocsFrequent();
            }, 1000);

            return () => {
                userSesssion("delete");
                if (editingUserId) {
                    updateEditingStatus("end")
                }
                clearInterval(interval);
            };
        }
    }, [validate])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function validateAccess() {
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, docsId: docsId, userOrgId: auth.activeCompany, docsOrgId: docsCreatedOrgId };
        if (props.fromOrgId) {
            postJson.userOrgId = props.fromOrgId
        }
        if (postJson.userOrgId === auth.activeCompany) {
            setRequestAccessShow(true)
        }

        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("validateAccess");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { ownerId, dataName, response:res } = responseData
                if (res === GlobalMsgCons.success) {
                    if (responseData.result) {
                        setValidate("verified")
                    } else {
                        setValidate("failed")
                        setRequestAccessData({ ownerId, dataName })
                    }
                } else if(res === GlobalMsgCons.notExist) {
                    setValidate("notexist")
                }

            }
        });
    }

    function getDocsFrequent() {
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, docsId, sessionId: auth.sessionId };;
        const request = new ZiFetchRequest();
        request.setDomain("poll");
        request.setEvent("user");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcPollingClient(props.docsGrpcPollingClient).pollZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let listOfMessageTypesWaiting = responseData.listOfMessageTypesWaiting;
                if (listOfMessageTypesWaiting !== undefined) {
                    notifyUsersChanges(listOfMessageTypesWaiting)
                }
            }
        });
    }

    function userSesssion(actionType) {
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, userId: auth.loginId, docsId, actionType, sessionId: auth.sessionId };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("userOpenAndCloseDocs");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();

            }
        });
    }

    function notifyUsersChanges(listOfMessageTypesWaiting) {
        listOfMessageTypesWaiting.map((item) => {
            let itemArr = [];
            let id = ""
            if (item.includes("::")) {
                itemArr = item.split("::");
                item = itemArr[0];
                id = itemArr[1];
            }
            switch (item) {
                case "DocsContentUpdated":
                    if (id === docsId) {
                        getDocsContent()
                    }
                    break;
                case "DocsEditStatusUpdated":
                    getEditingStatus()
                    break;
                case "DocsEditRequestUpdated":
                    let editUserId = itemArr[2];
                    if (id === docsId) {
                        setDocsEditRequestUserId(editUserId)
                        if (editingUserId !== auth.loginId) {
                            setDocsEditRequestModal(true)
                        }
                        setDocsNotifiShow(current => !current)
                    }
                    break;
                case "DocsEditRequestResponseUpdated":
                    let replyUserId = itemArr[2];
                    let replyMsg = itemArr[3];
                    if (id === docsId) {
                        setDocsReplyUserId(replyUserId)
                        setDocsReplyMsg(replyMsg)
                    }
                    break;
            }
        })
    }

    function onChange(content) {
        setEditorState(content)
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, userId: auth.loginId, docsId: docsId, content };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("write");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
            }
        });
    }

    function getDocsContent(refresh) {
        let postJson = { reqId: getUuidv4(), docsOrgId: docsCreatedOrgId, userId: auth.loginId, docsId: docsId, userOrgId: auth.activeCompany };

        if (props.fromOrgId) {
            postJson.userOrgId = props.fromOrgId
        }
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("getContent");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (responseData) {
                    let { name, content, ownerId, lastModifiedAt } = responseData.response
                    setLastModifiedAt(lastModifiedAt)
                    setOwnerId(ownerId)
                    if (filename === "") {
                        setFilename(name)
                    }
                    if (content) {
                        setEditorState(content.content)
                    }
                    if (refresh) {
                        toast(`Refreshed`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        setDocsRefresh(true)
                    }
                }
            }
        });
    }

    function getEditingStatus() {
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, editinguserId: auth.loginId, docsId };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("getEditingStatus");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                let { editingUserId, editingUserEmail } = responseData;
                setEditingUserId(editingUserId)
                setTypingUser(editingUserEmail)
            }
        });
    }

    function updateEditingStatus(status) {
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, editinguserId: auth.loginId, docsId, status };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("updateEditingStatus");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                if (responseData === GlobalMsgCons.success) {
                    let editingUserId = auth.loginId;
                    if (status === "end") {
                        editingUserId = "";
                    }
                    setEditingUserId(editingUserId)
                }

            }
        });
    }

    function getVersionContent(versionId, type) {
        let checkType = type === "restore";
        if (checkType) {
            restoreButtonLoading[versionId] = true
            setRestoreButtonLoading(restoreButtonLoading)
        } else {
            setVersionContentLoading(true)
        }
        let postJson = { reqId: getUuidv4(), userId: auth.loginId, docsId, versionId, docsOrgId: docsCreatedOrgId, userOrgId: auth.activeCompany };
        if (props.fromOrgId) {
            postJson.userOrgId = props.fromOrgId
        }
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("getVersionContent");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                if (checkType) {
                    restoreButtonLoading[versionId] = false
                    setRestoreButtonLoading(restoreButtonLoading)
                } else {
                    setVersionContentLoading(false)
                }
                let { content } = responseData.response;
                if (checkType) {
                    onChange(content.content)
                    setVersionModal(false)
                } else {
                    setVersionContent(content.content)
                }
            }
        });
    }

    function updateName() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, userId: auth.loginId, docsId: docsId, name: tempFilename };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("update");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
            }
        });
    }

    async function download(contentDocument) {
        alert("Development - In Progress");
        let filename = localStorage.getItem("file_" + docsId)
        // const data = await HTMLtoDOCX(contentDocument);
        // saveAs(data, filename+".docx");
    }

    async function example_image_upload_handler(blobInfo, progress) {
        let fileId = getUuidv4();
        let processType = "docs-upload";
        let filename = blobInfo.filename();
        const postFileUpload = new FormData();
        postFileUpload.append('file1', blobInfo.blob());
        postFileUpload.append('fileName1', filename);
        postFileUpload.append('processType1', processType);
        postFileUpload.append('docsId1', fileId);
        const response = await fetch(MyConstant.keyList.apiURL + "uploads/docs-upload/" + fileId + "/" + docsId, {
            method: "POST",
            mode: 'cors',
            redirect: 'follow',
            body: postFileUpload,
        }).then((response) => response.json()).catch(error => console.warn(error));

        var fileExt = filename.split('.').pop();
        return MyConstant.keyList.apiURL + "vp?action=" + processType + "&key=" + fileId + "." + fileExt + "&id=" + docsId
    }

    function doVersioningForDocs() {
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, userId: auth.loginId, docsId, flowType: "manual" };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("doVersioning");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn("Invalid Request. Please try again later")
            } else {
                if (response.getMessage() === GlobalMsgCons.success) {
                    toast.success("Updated successfully.", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    function getSharedUsersDetails() {
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, userId: auth.loginId, docsId, docsName: filename };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("getSharedUsersDetails");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = JSON.parse(response.getMessage());
                setSharedUserList(responseData.result)
            }
        });
    }
    function docsEditReq() {
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, userId: auth.loginId, docsId };
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("sendEditRequest");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                if (response.getMessage() === GlobalMsgCons.success) {
                    toast.success("Requested successfully.", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }

    function docsRelpyMsgsent() {
        let postJson = { reqId: getUuidv4(), orgId: docsCreatedOrgId, userId: auth.loginId, docsId, response: "is requesting you to wait", receiverId: docsEditRequestUserId };
        if (!docsEditRequestUserId) {
            return false
        }
        const request = new ZiFetchRequest();
        request.setDomain("docs");
        request.setEvent("sendResponseToDocsEditRequest");
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.docsGrpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                if (response.getMessage() === GlobalMsgCons.success) {
                    toast.success("Message sent successfully.", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }


    function documentRequestAccess() {
        let postJson = { reqId: getUuidv4(), orgId: auth.activeCompany, requestedUserId: auth.loginId, dataId: docsId, dataName: requestAccessData.dataName, dataType: "docs", ownerId: requestAccessData.ownerId };
        if (!requestAccessData.dataName || !requestAccessData.ownerId) {
            return false
        }
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.accessRequestNotificationToInbox);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.log("Invalid Request. Please try again later")
            } else {
                let responseData = response.getMessage();
                responseData = JSON.parse(responseData)
                if (responseData.response === GlobalMsgCons.success) {
                    toast.success("Requested successfully.", {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });
    }


    function getKey() {
        let { theme } = auth;
        let key = "theme-white";

        if (theme === "dark") {
            key = "theme-dark"
        }
        return key;
    }

    function getThemeBg() {
        let { theme } = auth;
        let returnTheme = "#fff";

        if (!window.location.pathname.includes("/manage/")) {
            if (theme === "dark") {
                returnTheme = "#1d2127"
            }
        }
        return returnTheme;
    }

    function getThemeText() {
        let { theme } = auth;
        let returnTheme = "#000";

        if (!window.location.pathname.includes("/manage/")) {
            if (theme === "dark") {
                returnTheme = "#fff"
            }
        }
        return returnTheme;
    }

    function getContent() {
        let content = <Loader />
        if (validate === "failed") {
            content = <div className="failed-setup">
                <i className="fas fa-times-circle"></i>
                <div className="mt-5">
                    You don't currently have to access this Docs
                </div>
                <div className="my-2">
                    <button className="btn btn-primary btn-sm" onClick={() => props.history.push(props.path + "/docs")}>
                        Back to Docs
                    </button>
                </div>
                {requestAccessShow ? <div className="my-2">
                    <button className="btn btn-success btn-sm" onClick={() => {
                        documentRequestAccess()
                    }}>
                        Request to access
                    </button>
                </div> : null}
            </div>
        } else if (validate === "notexist") {
            content = <div className="failed-setup">
                <i className="fas fa-times-circle"></i>
                <div className="mt-5">
                    The file does not exist
                </div>
                <div className="my-2">
                    <button className="btn btn-primary btn-sm" onClick={() => props.history.push(props.path + "/docs")}>
                        Back to Docs
                    </button>
                </div>
            </div>
        }
        else if (validate === "verified") {
            content = <React.Fragment>
                <div className={`header ${props.fromMobileView ? "flex-row d-flex-wrap" : ""}`}>
                    <ReactTooltip place="bottom" type="dark" effect="float" html={true} />
                    <div className="icon" onClick={() => {
                        if (!auth.fromMobileAccess) {
                            if (props.setPinTabInfo) {
                                props.setPinTabInfo({
                                    id: "",
                                    type: ""
                                })
                            }
                            if (fromGuest) {
                                props.history.goBack()
                            } else {
                                props.history.push(props.path + "/docs")
                            }
                        }

                    }} data-tip={"Back to Docs Home"}>
                        <i className="fa fa-file-alt" />
                    </div>
                    <div className="input">
                        <input
                            onChange={(e) => {
                                setTempFilename(e.target.value)
                            }}
                            onBlur={(e) => {
                                if (tempFilename !== "") {
                                    updateName()
                                }
                            }}
                            defaultValue={filename}
                            disabled={fromGuest ? fromGuest : false} />
                    </div>
                    <div className="extra">
                        {
                            !fromGuest && <React.Fragment>
                                <CopyUrlButton className={"mr-2"} />
                                <button className={`btn btn-sm btn-secondary share-button mr-2`} onClick={() => {
                                    setVersionModal(true)
                                }} data-tip={"Get version history"}><i className="fa fa-history" /></button>
                                <button className="btn btn-sm btn-info share-button mr-2" disabled={editingUserId !== "" && editingUserId !== auth.loginId} onClick={() => {
                                    let status = "end";
                                    if (editingUserId === "") {
                                        status = "start";
                                    }
                                    if (editingUserId === auth.loginId && lastModifiedAt == "") {
                                        if (props.docsNotificationMsg !== undefined) {
                                            props.setDocsNotificationMsg(true)
                                            getDocsContent()
                                        }
                                    }
                                    updateEditingStatus(status)
                                }} data-tip={editingUserId === auth.loginId ? "Click done to complete your edit" : "Click start to edit your document"}>{editingUserId === auth.loginId ? "Done" : "Start Editing"}</button>

                                {editingUserId !== "" && editingUserId !== auth.loginId && <button className="btn btn-sm btn-primary share-button mr-2" onClick={() => {
                                    docsEditReq()
                                }} data-tip={"Request editing"}>Request editing</button>}

                                <button className="btn btn-sm btn-success share-button mr-2" onClick={() => {
                                    doVersioningForDocs()
                                }} data-tip={"Backup your document"}>Backup</button>
                                <ShareModal
                                    domain={"docs"}
                                    getSharedUserDetailsEvent={"getSharedUsersDetails"}
                                    sharingEvent={"docSharing"}
                                    postJsonInfo={{ docsId, docsName: filename }}
                                    dataId={docsId}
                                    dataName={filename}
                                    dataType={"docs"}
                                    grpcClient={props.grpcClient}
                                    searchGrpcClient={props.searchGrpcClient}
                                    docsGrpcClient={props.docsGrpcClient}
                                    parentOrgInfo={parentOrgInfo}
                                    ownerId={ownerId}
                                />
                                <ViewedByModal
                                    domain={"docs"}
                                    getSharedUserDetailsEvent={"getSharedUsersDetails"}
                                    postJsonInfo={{ docsId, docsName: filename }}
                                    dataId={docsId}
                                    dataName={filename}
                                    dataType={"docs"}
                                    grpcClient={props.grpcClient}
                                    searchGrpcClient={props.searchGrpcClient}
                                    docsGrpcClient={props.docsGrpcClient}
                                    parentOrgInfo={parentOrgInfo}
                                />
                                {
                                    !checkMobileView() && <button className={`btn btn-sm btn-${props.pinTabInfo.id === docsId && props.pinTabInfo.type === "docs" ? "success" : "secondary"} pin-button ml-2`} onClick={() => {
                                        if (props.pinTabInfo.id === docsId && props.pinTabInfo.type === "docs") {
                                            props.setPinTabInfo({
                                                id: "",
                                                type: ""
                                            })
                                        } else {
                                            props.setPinTabInfo({
                                                id: docsId,
                                                type: "docs"
                                            })
                                        }
                                    }} data-tip={"Pin your document"}><i className="fas fa-thumbtack" /></button>
                                }
                            </React.Fragment>
                        }

                        <button className={`btn btn-sm btn-${docsRefresh ? "secondary" : "primary"} pin-button ml-2`} onClick={() => {
                            setDocsRefresh(false)
                            getDocsContent(true)
                        }} data-tip={"Refresh"}><i className="fas fa-sync-alt" /></button>
                    </div>
                </div>
                <Editor
                    key={getKey()}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue=""
                    value={editorState}
                    disabled={editingUserId !== auth.loginId}
                    init={{
                        height: "100%",
                        menubar: true,
                        promotion: false,
                        branding: false,
                        elementpath: false,
                        browser_spellcheck: toggleSpellCheck,
                        relative_urls: false,
                        remove_script_host: false,
                        images_upload_handler: example_image_upload_handler,
                        setup: function (editor) {
                            editor.on('init', function (e) {
                                setEditorState("")
                                getDocsContent();
                                getEditingStatus()
                            });

                            editor.on('focus', function (e) {
                                // console.log("focus");
                            });

                            editor.ui.registry.addToggleMenuItem('spellchecktool', {
                                text: 'Spellcheck',
                                icon: 'spell-check',
                                onAction: () => {
                                    toggleSpellCheck = !toggleSpellCheck;
                                    editor.getBody().setAttribute('spellcheck', toggleSpellCheck);
                                    localStorage.setItem("spellCheck", toggleSpellCheck ? "on" : "off")
                                },
                                onSetup: (api) => {
                                    api.setActive(toggleSpellCheck);
                                    return () => { };
                                }
                            });
                            editor.ui.registry.addToggleMenuItem('download', {
                                text: 'Download',
                                icon: 'save',
                                onAction: () => {
                                    let contentDocument = editor.getContent();
                                    download(contentDocument)
                                }
                            });
                            // editor.ui.registry.addToggleMenuItem('versionHistory', {
                            //     text: 'Version History',
                            //     icon: 'restore-draft',
                            //     onAction: () => {
                            //         setVersionModal(true)
                            //     }
                            // });

                            editor.on("KeyUp", function (e) {
                                var sel = editor.selection.getSel();
                                var caretPos = sel.anchorOffset;
                                var txtData = sel.anchorNode.textContent;

                                // Char code 160 is a non breaking space
                                const lineMatch = `${AUTOBULLET_TRIGGER_CHARACTER}${String.fromCharCode(
                                    160
                                )}`;

                                if (e.key === " " && caretPos === 3 && txtData === lineMatch) {
                                    if (
                                        AUTOBULLET_VALID_CONTAINERS.includes(
                                            sel.focusNode.parentElement.constructor.name
                                        )
                                    ) {
                                        // Create an unordered list
                                        editor.execCommand('InsertOrderedList', false, {
                                            'list-style-type': 'decimal',
                                        });

                                        // Delete the last two characters from the cursor position,
                                        // which we know are "- " since that's what triggered the autobullet
                                        //
                                        // Modified from https://stackoverflow.com/a/43798749
                                        const edRange = editor.selection.getRng();
                                        const edNode = edRange.commonAncestorContainer;
                                        let range = document.createRange(); // create a new range object for the deletion
                                        range.selectNodeContents(edNode);
                                        range.setStart(edNode, edRange.endOffset - 3); // current caret pos - 3
                                        range.setEnd(edNode, edRange.endOffset); // current caret pos
                                        range.deleteContents();
                                    }
                                }
                            });

                        },
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'textpattern', 'nonbreaking', 'pagebreak'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic underline forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | link| pagebreak | image  | help ',
                        pagebreak_split_block: true,
                        content_style: `
                            body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
                            p { margin: 0}
                            dl, ol, ul { margin: 0 }     
                            .mce-content-body {
                                background: ${getThemeBg()};
                                color: ${getThemeText()}
                            }  
                        `,
                        menu: {
                            file: { title: 'File', items: 'preview | print | versionHistory' },
                            edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                            view: { title: 'View', items: 'code | visualaid visualblocks | preview fullscreen' },
                            insert: { title: 'Insert', items: 'image link media inserttable | charmap hr | anchor | insertdatetime' },
                            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
                            tools: { title: 'Tools', items: 'spellchecktool | code wordcount' },
                            table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
                            help: { title: 'Help', items: 'help' }
                        },
                        nonbreaking_force_tab: true
                    }}
                    onEditorChange={(content) => {
                        if (content !== editorState) {
                            onChange(content)
                        }
                    }}
                />
            </React.Fragment>
        }

        return content;
    }

    return <div className="docs-write">
        {
            getContent()
        }
        {
            (editingUserId && editingUserId !== auth.loginId && typingUser) && <div className="typing-info-display">{`${typingUser} is typing`}</div>
        }

        {
            versionModal && <Modal
                visible={versionModal}
                size={"modal-xl"}
                closeModal={() => {
                    setVersionModal(false)
                    setVersionContentLoading(false)
                    setVersionContent("")
                }}
                heading={"Version History"}
                body={<React.Fragment>
                    <div className="row">
                        <div className="col-sm-7">
                            <PaginationDatatable
                                grpcClient={props.docsGrpcClient}
                                domain={"docs"}
                                event={"getVersionList"}
                                data={[
                                    {
                                        column: "Created User",
                                        cell: (row, key) => {
                                            return <div>{row.createdByUserEmail ? row.createdByUserEmail : "-"}</div>
                                        }
                                    },
                                    {
                                        column: "Created At",
                                        cell: (row, key) => {
                                            return <div>{getDateWithFormat(row.createdAt, auth.activeCompanyDetails)}</div>
                                        }
                                    },
                                    {
                                        column: "Version Type",
                                        cell: (row, key) => {
                                            return <div>{row.flowType}</div>
                                        }
                                    },
                                    {
                                        column: "View",
                                        cell: (row, key) => {
                                            return <button className="btn btn-sm btn-primary" onClick={() => {
                                                getVersionContent(row.versionId)
                                            }}>View</button>
                                        }
                                    },
                                    {
                                        column: "Restore",
                                        cell: (row, key) => {
                                            return (checkActiveAdmin(auth.activeUserType, auth.systemLevelRole)) ? <button className="btn btn-sm btn-info" onClick={() => {
                                                if (window.confirm("Are you sure restore this version data ?")) {
                                                    getVersionContent(row.versionId, "restore")
                                                }

                                            }}>Restore {restoreButtonLoading[row.versionId] ? <>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </> : ""} </button> : "-"
                                        }
                                    },
                                ]}
                                postJsonInfo={{ orgId: docsCreatedOrgId, userId: auth.loginId, docsId }}
                                refresh={false}
                            />
                        </div>
                        <div className="col-sm-5 version-view">
                            {
                                versionContentLoading ? <Loader /> : <div dangerouslySetInnerHTML={{ __html: versionContent }}></div>
                            }
                        </div>
                    </div>
                </React.Fragment>
                }
            />
        }
        {docsEditRequestUserId && sharedUserList[docsEditRequestUserId] && editingUserId === auth.loginId && <Modal
            visible={docsEditRequestModal}
            size="modal-sm"
            heading={"Docs Notification"}
            closeModal={() => {
                setDocsEditRequestModal(false)
            }}
            body={<React.Fragment>
                <div className="mb-2 text-center">
                    <span><b className="letters">{sharedUserList[docsEditRequestUserId].emailId} </b> has sent a request to edit the document</span>
                </div>

                {editingUserId === auth.loginId ? <div className="d-flex justify-content-center">
                    <button className="btn btn-sm btn-success mr-2" onClick={() => {
                        setDocsEditRequestModal(false)
                        updateEditingStatus("end")
                    }}>Okay</button>
                    <button className="btn btn-sm btn-primary" onClick={() => {
                        setDocsEditRequestModal(false)
                        docsRelpyMsgsent()
                    }}>Wait</button>
                </div> : null}


            </React.Fragment>}
        />
        }

        {docsReplyUserId && sharedUserList[docsReplyUserId] && <Modal
            visible={true}
            size="modal-sm"
            heading={"Docs Notification"}
            closeModal={() => {
                setDocsReplyUserId("")
                setDocsReplyMsg("")
            }}
            body={<React.Fragment>
                <div className="mb-2 text-center">
                    <span><b className="letters">{sharedUserList[docsReplyUserId].emailId} </b> {docsReplyMsg}</span>
                </div>
            </React.Fragment>}
        />
        }

    </div>

}
export default DocsWrite;
